import Layout from '../components/layout/Layout';
import React from 'react';

const NotFound = () => (
  <Layout>
    <h1>Has llegado al fin del internet</h1>
    <p>Esta página no existe.</p>
  </Layout>
);

export default NotFound;
